/* eslint-disable import/no-anonymous-default-export */
import { TABLE_GET_DATA, TABLE_GET_DATA_BY_DATE, TABLE_GET_DATA_BY_DATE_PERIOD, TABLE_CHANGE_FIELD} from '../actions/types'

const buff = new Date(Date.now())
let day ='' + buff.getDate()
let month ='' + (buff.getMonth()+1)
const year ='' + buff.getFullYear()
if (month.length < 2) 
        month = '0' + month;
if (day.length < 2) 
        day = '0' + day;

const DEFAULT_STATE = {
  data: [],
  statistic: {},
  filterdByDate: false,
  period: null,
  day: `${year}-${month}-${day}`,
  currentPage: 1,
  lastPage: null,
  from: null,
  to: null,
  total: null
}

// if (month.length < 2) 
//         month = '0' + month;
//     if (day.length < 2) 
//         day = '0' + day;


// {
//   year: buff.getFullYear(),
//   month: buff.getMonth()+1,
//   day: buff.getDate(),
// }


export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
      case TABLE_GET_DATA:
          return { ...state, data: action.payload.data, statistic: action.payload.statistic, lastPage: action.payload.meta.last_page, currentPage: action.payload.meta.current_page, from: action.payload.meta.from, to: action.payload.meta.to, total: action.payload.meta.total }
      case TABLE_GET_DATA_BY_DATE:
          return {...state, data: action.payload.data.data, statistic:  action.payload.data.statistic, filterdByDate:true, period: null, day: action.payload.day, lastPage: action.payload.data.meta.last_page, currentPage: action.payload.data.meta.current_page, from: action.payload.data.meta.from, to: action.payload.data.meta.to, total: action.payload.data.meta.total}
      case TABLE_GET_DATA_BY_DATE_PERIOD:
          return {...state, data: action.payload.data.data, statistic:  action.payload.data.statistic, filterdByDate:true, period: action.payload.period, day: null, lastPage: action.payload.data.meta.last_page, currentPage: action.payload.data.meta.current_page, from: action.payload.data.meta.from, to: action.payload.data.meta.to, total: action.payload.data.meta.total}
      case TABLE_CHANGE_FIELD: {
        const newArray = [...state.data]; //making a new array
        newArray[action.payload.index][action.payload.fieldName] = action.payload.value//changing value in the new array
        // console.log(newArray)
        return { 
         ...state, //copying the orignal state
         data: newArray, //reassingning todos to new array
        }
      }
          

      default:
          return state;
  }
  
}