import styles from "./admintable.module.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";


function AdminTable(props) {
  // const [Trigger, setTrigger] = useState([])
  const [showWork, setShowWork] = useState({show: false,
                                            id: null})
  const [showRegion, setShowRegion] = useState({show: false,
                                            id: null})
  const [showDelay, setShowDelay] = useState({show: false,
                                                id: null})
  const [showAlpha, setShowAlpha] = useState({show: false,
                                              id: null})

  const logoList = [{value: "На номер телефона", className: styles.noLogo}, {value: "Telegram", className: styles.tgLogo}, {value: "Viber", className: styles.vbLogo}, {value: "Whats App", className: styles.waLogo}, {value: null, className: styles.noLogo}]

  const regions = ["Брест", "Брестская область", "Витебск", "Витебская область", "Гомель", "Гомельская область", "Гродно", "Гродненская область", "Минск", "Минская область", "Могилёв", "Могилевская область"]



  const handleChangeRegion = (userId, value, index) => {
    props.changeRegion({id: userId, region: value}, index, "region")
    // setTrigger(!Trigger)
  }

  const handleChangeWork = (userId, value, index) => {
    props.changeWork({id: userId, is_work: value}, index, "is_work")
    // setTrigger(!Trigger)
  }



  const handleChangeIsDelay = (userId, value, index) => {
    props.changeIsDelay({id: userId, is_delays: value}, index, "is_delays")
    // setTrigger(!Trigger)
  }

  const handleChangeAlphaStatus = (userId, value, index) => {
    props.changeAlphaStatus({id: userId, alpha_status: value}, index, "alpha_status")
    // setTrigger(!Trigger)
  }

  const handleChangeCreditStatus = (userId, value, index) => {
    props.changeCreditStatus({id: userId, credit_status: value}, index, "credit_status")
    // setTrigger(!Trigger)
  }

  const handleChangeAlphaWhere = (userId, value, index) => {
    props.changeAlphaWhere({id: userId, alpha_where: value}, index, "alpha_where")
    // setTrigger(!Trigger)
  }



  const handleChangeHalvaOffer =  (userId, value, index) => {
    props.changeHalvaOffer({id: userId, halva_offer: value}, index, "halva_offer")
    // setTrigger(!Trigger)
  }

  const handleChangeAlphaBank = (userId, value, index) => {
    props.changeAlphaBank({id: userId, alpha_bank: value}, index, "alpha_bank")
    // console.log(value)
    // setTrigger(!Trigger)
  }



  const handleChangeAlphaAction = (userId, value, index) => {
    props.changeAlphaAction({id: userId, alpha_action: value}, index, "alpha_action")
    // setTrigger(!Trigger)
  }



  const handleChangeCreditActionValid = (id, index) => {
    props.changeCreditActionOnValid({id}, index, "credit_action")
    // setTrigger(!Trigger)
  }

  const handleChangeCreditActionInValid = (id, index) => {
    props.changeCreditActionOnInValid({id}, index, "credit_action")
    // setTrigger(!Trigger)
  }


  useEffect(() =>  {
    // console.log(localStorage.getItem('JWT'))
    // if (props.data.table.filterdByDate) {
    //   if (props.data.table.period) {
    //     props.getDataByDatePeriod({from: props.data.table.period.from, to: props.data.table.period.to, page: props.data.table.currentPage})
    //   } else {
    //     props.getDataByDate({date: props.data.table.day, page: props.data.table.currentPage})
        
    //   }
    // } else {
    //   props.getData({page : props.data.table.currentPage})
    // }
    const interval = setInterval(() => {
      // console.log("interval")
      if (props.data.table.filterdByDate) {
        if (props.data.table.period) {
          props.getDataByDatePeriod({from: props.data.table.period.from, to: props.data.table.period.to, page: props.data.table.currentPage})
        } else {
          props.getDataByDate({date: props.data.table.day, page: props.data.table.currentPage})
          
        }
      } else {
        props.getData({page : props.data.table.currentPage})
      }
    },(900 * 1000));
    return () => clearInterval(interval);
  }, [])




  return (
    <div className={styles.wrapper}>
      {/* <div> */}
    <table className={styles.adminTable} >
  <thead>
  
    <tr>
      <th>Номер лида</th>
      <th>Дата</th>
      <th>Время</th>
      <th>Имя</th>
      <th>Телефон</th>
      <th>Регион</th>
      <th>Работает?</th>
      <th>Просрочка?</th>
      <th>Халва сайт?</th>
      <th>Халва КЦ</th>
      <th>Альфа КЦ</th>
      <th>Альфа куда?</th>
      <th>Альфа статус</th>
      <th>Альфа Действие</th>
      <th>Кредит статус</th>
      <th>Кредит действие</th>
      <th>Источник</th>
      <th>utm_source</th>
      <th>Получатель</th>
      <th>Менеджер</th>


    </tr>
  </thead>
  <tbody>
    {/* {fields} */}
    {props.data.table.data.map((item, index )=>
      <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.date}</td>
      <td>{item.time}</td>
      <td>{item.full_name}</td>
      <td>{item.phone}</td>
      <td>
      <div className={styles.region} onClick={() => {setShowRegion({show: !showRegion.show, id: item.id})}}>
        <div className={styles.currentRegion}  onBlur={() => {setShowRegion({show: false, id: null})}} >
          {item.region}
        </div>
        {showRegion.show && showRegion.id === item.id ? 
        <div className={styles.regionList}>
          {regions.map((item1) => item1 !== item.region ? 
          <div key={item1.value} className={styles.regionList__item} onClick={() => {
            // setData(data.map((buff) => buff.id === item.id ? { ...buff, region: item1 } : buff));
            handleChangeRegion(item.id, item1, index)
            setShowRegion({show: false, id: null})
          }}>
            {item1}
          </div> : null)}
          
        </div> 
        : null}
        </div>
      </td>
      <td  >
        <div className={styles.work}>
        <div className={styles.currentWork}  onBlur={() => {setShowWork({show: false, id: null})}} onClick={() => {setShowWork({show: !showWork.show, id: item.id})}}>
          {item.is_work}
        </div>
        {showWork.show && showWork.id === item.id ? 
        <div className={styles.workList}>
          <div className={styles.workList__item} onClick={() => {
            // setData(data.map((item1) => item1.id === item.id ? { ...item1, is_work: item.is_work === "Да" ? "Нет" : "Да" } : item1));
            handleChangeWork(item.id, item.is_work === "да" ? "нет" : "да", index)
            setShowWork({show: false, id: null})
          }}>
            {item.is_work === "нет" ? "да" : "нет"}
          </div>
        </div> 
        : null}
        </div>
        
      </td>
      <td>
      <div className={styles.work}>
        <div className={styles.currentWork}  onBlur={() => {setShowDelay({show: false, id: null})}} onClick={() => {setShowDelay({show: !showDelay.show, id: item.id})}}>
          {item.is_delays}
        </div>
        {showDelay.show && showDelay.id === item.id ? 
        <div className={styles.workList}>
          <div className={styles.workList__item} onClick={() => {
            // setData(data.map((item1) => item1.id === item.id ? { ...item1, is_delays:item.is_delays === "Да" ? "Нет" : "Да" } : item1));
            handleChangeIsDelay(item.id, item.is_delays === "да" ? "нет" : "да", index)
            setShowDelay({show: false, id: null})
          }}>
            {item.is_delays === "да" ? "нет" : "да"}
          </div>
        </div> 
        : null}
        </div>
      </td>
      <td>{item.halva_site}</td>
      <td >
        {item.halva_offer !== "1"  && item.is_work === "да" && item.is_delays === "нет" && item.halva_site !== "Авто" && item.manager !== "Авто" ? <div className={styles.halva__button} onClick={() => {handleChangeHalvaOffer(item.id, 1, index)}}>Отправить халву</div> :  item.is_work !== "да" || item.is_delays !== "нет" || item.halva_site === "Авто" || item.manager === "Авто" ? <div className={styles.halva__no}>Недоступно</div> : <div className={styles.halva__text}>Отправленно</div>}
        </td>
      <td>
        <div className={styles.alphaBank}>
          <div className={styles.alphaYes} onClick={() => {handleChangeAlphaBank(item.id, 1, index)}} style={item.alpha_bank === "1" ? {background: "#39BA42", color: "#ffffff"} : null}>
            Да
          </div>
          <div className={styles.alphaNo} onClick={() => {handleChangeAlphaBank(item.id, 0, index)}} style={item.alpha_bank === "0" ? {background: "#EE0064", color: "#ffffff"} : null}>
            Нет
          </div>
        </div>
      </td>
      <td>
        <div className={styles.alphaWhere}>
          { item.alpha_bank === "1" ?
          <div className={styles.currentAlpha} onClick={() => {setShowAlpha({show: !showAlpha.show, id: item.id})}}>
            <div className={logoList.find(x => x.value === item.alpha_where) ? logoList.find(x => x.value === item.alpha_where).className : styles.noLogo }/>
            {/* <div className={logoList.map(x => x.value === data[i].alpha_where)[0].className}/> */}
            <div className={styles.currentAlpha__text}>
              {item.alpha_where ? item.alpha_where : "По номеру телефона"}
            </div>
            <div className={styles.currentAlpha__arrow}/>
          </div>
            : <div className={styles.halva__no} style={{alignSelf: "center"}}>Недоступно</div>}
          {showAlpha.show && showAlpha.id === item.id ? 
        <div className={styles.alphaList}>
          {logoList.map((item1) => item1.value !== item.alpha_where && item1.value !== null? 
          <div kye={item1.value} className={styles.alphaList__item} onClick={() => {
            // setData(data.map((buff) => buff.id === item.id ? { ...buff, alpha_where: item1.value } : buff));
            handleChangeAlphaWhere(item.id, item1.value, index)
            setShowAlpha({show: false, id: null})
          }}>
            <div className={item1.className}/>
            {item1.value}
          </div> : null)}
          </div> 
        : null}
        </div>
      </td>
      <td>
        <div className={styles.alphaStatus}>
          <div className={(item.alpha_bank === "1" && (item.alpha_status !== "Написали" && item.alpha_status !== "В процессе" )) && (item.alpha_action === null) ? styles.alphaNew : styles.alphaNew__no} onClick = {item.alpha_bank === "1" && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => {handleChangeAlphaStatus(item.id, "Новый", index)} : null}>
            Новый
          </div>
          <div className={(item.alpha_bank === "1" && item.alpha_status === "Написали") && (item.alpha_action === null) ? styles.alphaSend : styles.alphaSend__no} onClick = {item.alpha_bank === "1" && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => {handleChangeAlphaStatus(item.id, "Написали", index)} : null}>
            Написали
          </div>
          <div className={(item.alpha_bank === "1" && item.alpha_status === "В процессе") && (item.alpha_action === null) ? styles.alphaGo : styles.alphaGo__no} onClick = {item.alpha_bank === "1" && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => {handleChangeAlphaStatus(item.id, "В процессе", index)} : null}>
            В процессе
          </div>
        </div>
      </td>
      <td>
        <div className={styles.alphaAction}>
          <div className={item.alpha_bank !== "1" ? styles.alphaActionOk__no : item.alpha_action !== "0" && item.alpha_action !== "1" ? styles.alphaActionOk : styles.alphaActionOk__no} onClick={item.alpha_bank && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => { handleChangeAlphaAction(item.id, 1, index)} : null}>
            <div className={styles.alphaOkImg}/>
            Успешно
          </div>
          <div className={item.alpha_bank !== "1" ? styles.alphaActionNotOk__no : item.alpha_action !== "0" && item.alpha_action !== "1" ? styles.alphaActionNotOk : styles.alphaActionNotOk__no} onClick={item.alpha_bank && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => { handleChangeAlphaAction(item.id, 0, index)} : null}>
            <div className={styles.alphaNotOkImg}/>
            Проигрыш
          </div>
        </div>
      </td>
      <td>
        <div className={styles.alphaStatus}>
          <div className={item.credit_action === "3" && (item.credit_status === "Новый" || item.credit_status === null ) ? styles.alphaNew : styles.alphaNew__no} onClick = {item.credit_action !== "0" && item.credit_action !== "1" ? () => {handleChangeCreditStatus(item.id, "Новый", index)} : null}>
            Новый
          </div>
          <div className={item.credit_action === "3" && item.credit_status === "Недозвон 1"  ? styles.alphaSend : styles.alphaSend__no} onClick = {item.credit_action !== "0" && item.credit_action !== "1" ? () => {handleChangeCreditStatus(item.id, "Недозвон 1", index)} : null}>
            Недозвон 1
          </div>
          <div className={item.credit_action === "3" && item.credit_status === "Недозвон 2"  ? styles.alphaGo : styles.alphaGo__no} onClick = {item.credit_action !== "0" && item.credit_action !== "1" ? () => {handleChangeCreditStatus(item.id, "Недозвон 2", index)} : null}>
            Недозвон 2
          </div>
        </div>
      </td>
      <td>
        <div className={styles.alphaAction}>
          <div className={item.credit_action !== "0" && item.credit_action !== "1" && item.manager !== "Авто" ? styles.alphaActionOk : styles.alphaActionOk__no} onClick={item.credit_action !== "0" && item.credit_action !== "1" && item.manager !== "Авто" ? ()=>{handleChangeCreditActionValid(item.id, index)} : null}>
            <div className={styles.alphaOkImg}/>
            Отправить
          </div>
          <div className={item.credit_action !== "1" && item.credit_action !== "0" && item.manager !== "Авто" ? styles.alphaActionNotOk : styles.alphaActionNotOk__no} onClick={item.credit_action !== "0" && item.credit_action !== "1" && item.manager !== "Авто" ? ()=>{handleChangeCreditActionInValid(item.id, index)} : null}>
            <div className={styles.alphaNotOkImg}/>
            Невалид
          </div>
        </div>
      </td>
      <td>{item.source}</td>
      <td>{item.utm_source}</td>
      <td>{item.recipient}</td>
      <td>{item.manager}</td>
    </tr> 
    )}
  </tbody>
</table>
{/* </div> */}
</div>
  );
}

function mapStateToProps(state) {
  return {
    data: state,
  };
}

export default connect(mapStateToProps, actions)(AdminTable);

