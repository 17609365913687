/* eslint-disable import/no-anonymous-default-export */
import { AUTH_ERROR, AUTH_SIGN_IN, AUTH_CHECK, AUTH_SIGN_OUT, GET_CURRENT_USER} from '../actions/types'

const DEFAULT_STATE = {
  isAuthenticated: false,
  token: '',
  errorMessage: '',
  user: {},
  isWorking: null,
  id: null,
}

export default (state = DEFAULT_STATE, action) => {
  switch(action.type) {
      case AUTH_SIGN_IN:
          return { ...state, token: action.payload.access_token, name: action.payload.name, role: action.payload.role, isAuthenticated: true, errorMessage: '' }
      case AUTH_CHECK:
          return { ...state, token: action.payload.data.access_token, isAuthenticated: action.payload.isAuth }
      case AUTH_SIGN_OUT:
            return { ...state, token: action.payload, isAuthenticated: false, errorMessage: '' }
      case AUTH_ERROR: 
          return { ...state, errorMessage: action.payload }
      case GET_CURRENT_USER: 
          return { ...state, isWorking: action.payload.is_working, id: action.payload.id }
      default:
          return state;
  }
  
}