import styles from "./admintable.module.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";


function ManagerAlpha(props) {
  // const [Trigger, setTrigger] = useState([])

  const [showAlpha, setShowAlpha] = useState({show: false,
                                              id: null})

  const logoList = [{value: "На номер телефона", className: styles.noLogo}, {value: "Telegram", className: styles.tgLogo}, {value: "Viber", className: styles.vbLogo}, {value: "Whats App", className: styles.waLogo}, {value: null, className: styles.noLogo}]

  const handleChangeAlphaWhere = (userId, value, index) => {
    props.changeAlphaWhere({id: userId, alpha_where: value}, index, "alpha_where")
    // setTrigger(!Trigger)
  }


  const handleChangeAlphaStatus = (userId, value, index) => {
    props.changeAlphaStatus({id: userId, alpha_status: value}, index, "alpha_status")
    // setTrigger(!Trigger)
  }


  const handleChangeAlphaAction = (userId, value, index) => {
    props.changeAlphaAction({id: userId, alpha_action: value}, index, "alpha_action")
    // setTrigger(!Trigger)
  }


  useEffect(() =>  {
    // console.log(localStorage.getItem('JWT'))
    // if (props.data.table.filterdByDate) {
    //   if (props.data.table.period) {
    //     props.getDataByDatePeriod({from: props.data.table.period.from, to: props.data.table.period.to, page: props.data.table.currentPage})
    //   } else {
    //     props.getDataByDate({date: props.data.table.day, page: props.data.table.currentPage})
        
    //   }
    // } else {
    //   props.getData({page : props.data.table.currentPage})
    // }
    const interval = setInterval(() => {
      // console.log("interval")
      if (props.data.table.filterdByDate) {
        if (props.data.table.period) {
          props.getDataByDatePeriod({from: props.data.table.period.from, to: props.data.table.period.to, page: props.data.table.currentPage})
        } else {
          props.getDataByDate({date: props.data.table.day, page: props.data.table.currentPage})
          
        }
      } else {
        props.getData({page : props.data.table.currentPage})
      }
    },(900 * 1000));
    return () => clearInterval(interval);
  }, [])

  return (
    <div className={styles.wrapper}>
    <table className={styles.managerAlphaTable}>
  <thead>
  
    <tr>
      <th>Номер лида</th>
      <th>Дата</th>
      <th>Время</th>
      <th>Имя</th>
      <th>Телефон</th>
      <th>Альфа куда?</th>
      <th>Альфа статус</th>
      <th>Альфа Действие</th>
    </tr>
  </thead>
  <tbody>
    {props.data.table.data.map((item, index )=>
      <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.date}</td>
      <td>{item.time}</td>
      <td>{item.full_name}</td>
      <td>{item.phone}</td>
      <td>
        <div className={styles.alphaWhere}>
          { item.alpha_bank === "1" ?
          <div className={styles.currentAlpha} onClick={() => {setShowAlpha({show: !showAlpha.show, id: item.id})}}>
            <div className={logoList.find(x => x.value === item.alpha_where) ? logoList.find(x => x.value === item.alpha_where).className : styles.noLogo }/>
            {/* <div className={logoList.map(x => x.value === data[i].alpha_where)[0].className}/> */}
            <div className={styles.currentAlpha__text}>
              {item.alpha_where ? item.alpha_where : "По номеру телефона"}
            </div>
            <div className={styles.currentAlpha__arrow}/>
          </div>
            : <div className={styles.halva__no} style={{alignSelf: "center"}}>Недоступно</div>}
          {showAlpha.show && showAlpha.id === item.id ? 
        <div className={styles.alphaList}>
          {logoList.map((item1) => item1.value !== item.alpha_where && item1.value !== null? 
          <div kye={item1.value} className={styles.alphaList__item} onClick={() => {
            // setData(data.map((buff) => buff.id === item.id ? { ...buff, alpha_where: item1.value } : buff));
            handleChangeAlphaWhere(item.id, item1.value, index)
            setShowAlpha({show: false, id: null})
          }}>
            <div className={item1.className}/>
            {item1.value}
          </div> : null)}
          </div> 
        : null}
        </div>
      </td>
      <td>
        <div className={styles.alphaStatus}>
          <div className={(item.alpha_bank === "1" && (item.alpha_status !== "Написали" && item.alpha_status !== "В процессе" )) && (item.alpha_action === null) ? styles.alphaNew : styles.alphaNew__no} onClick = {item.alpha_bank === "1" && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => {handleChangeAlphaStatus(item.id, "Новый", index)} : null}>
            Новый
          </div>
          <div className={(item.alpha_bank === "1" && item.alpha_status === "Написали") && (item.alpha_action === null) ? styles.alphaSend : styles.alphaSend__no} onClick = {item.alpha_bank === "1" && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => {handleChangeAlphaStatus(item.id, "Написали", index)} : null}>
            Написали
          </div>
          <div className={(item.alpha_bank === "1" && item.alpha_status === "В процессе") && (item.alpha_action === null) ? styles.alphaGo : styles.alphaGo__no} onClick = {item.alpha_bank === "1" && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => {handleChangeAlphaStatus(item.id, "В процессе", index)} : null}>
            В процессе
          </div>
        </div>
      </td>
      <td>
        <div className={styles.alphaAction}>
          <div className={item.alpha_bank !== "1" ? styles.alphaActionOk__no : item.alpha_action !== "0" && item.alpha_action !== "1" ? styles.alphaActionOk : styles.alphaActionOk__no} onClick={item.alpha_bank && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => { handleChangeAlphaAction(item.id, 1, index)} : null}>
            <div className={styles.alphaOkImg}/>
            Успешно
          </div>
          <div className={item.alpha_bank !== "1" ? styles.alphaActionNotOk__no : item.alpha_action !== "0" && item.alpha_action !== "1" ? styles.alphaActionNotOk : styles.alphaActionNotOk__no} onClick={item.alpha_bank && item.alpha_action !== "0" && item.alpha_action !== "1" ? () => { handleChangeAlphaAction(item.id, 0, index)} : null}>
            <div className={styles.alphaNotOkImg}/>
            Проигрыш
          </div>
        </div>
      </td>
    </tr> 
    )}
  </tbody>
</table>
</div>
  );
}

function mapStateToProps(state) {
  return {
    data: state,
  };
}

export default connect(mapStateToProps, actions)(ManagerAlpha);

