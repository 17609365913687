import styles from "./header.module.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";


function Header(props) {

  const user = {
    name: localStorage.getItem('NAME'),
    role: localStorage.getItem('ROLE')
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.left}>
          CRM
        </div>
        <div className={styles.right}>
          <div className={styles.name}>
            Вы вошли как <span> {user.name}</span>
          </div>
          <div className={styles.role}>
            Уровень доступа - <span> {user.role === "admin" ? "Администратор" : user.role === "manager-kc" ? "Менеджер КЦ" : "Менеджер Альфа"}</span>
          </div>
          <div className={styles.account} onClick={()=>props.signOut()}>
            <div className={styles.account__img}/>
            <div className={styles.account__name}>
              {user.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuth: state,
  };
}

export default connect(mapStateToProps, actions)(Header);

