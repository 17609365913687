import styles from "./admintable.module.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";


function ManagerKC(props) {
  // const [Trigger, setTrigger] = useState([])
  const [showWork, setShowWork] = useState({show: false,
                                            id: null})
  const [showRegion, setShowRegion] = useState({show: false,
                                            id: null})
  const [showDelay, setShowDelay] = useState({show: false,
                                                id: null})
  const regions = ["Брест", "Брестская область", "Витебск", "Витебская область", "Гомель", "Гомельская область", "Гродно", "Гродненская область", "Минск", "Минская область", "Могилёв", "Могилевская область"]

  const handleChangeRegion = (userId, value, index) => {
    props.changeRegion({id: userId, region: value}, index, "region")
    // setTrigger(!Trigger)
  }

  const handleChangeWork = (userId, value, index) => {
    props.changeWork({id: userId, is_work: value}, index, "is_work")
    // setTrigger(!Trigger)
  }



  const handleChangeIsDelay = (userId, value, index) => {
    props.changeIsDelay({id: userId, is_delays: value}, index, "is_delays")
    // setTrigger(!Trigger)
  }

  const handleChangeCreditStatus = (userId, value, index) => {
    props.changeCreditStatus({id: userId, credit_status: value}, index, "credit_status")
    // setTrigger(!Trigger)
  }

  const handleChangeHalvaOffer =  (userId, value, index) => {
    props.changeHalvaOffer({id: userId, halva_offer: value}, index, "halva_offer")
    // setTrigger(!Trigger)
  }

  const handleChangeAlphaBank = (userId, value, index) => {
    props.changeAlphaBank({id: userId, alpha_bank: value}, index, "alpha_bank")
    // console.log(value)
    // setTrigger(!Trigger)
  }

  const handleChangeCreditActionValid = (id, index) => {
    props.changeCreditActionOnValid({id}, index, "credit_action")
    // setTrigger(!Trigger)
  }

  const handleChangeCreditActionInValid = (id, index) => {
    props.changeCreditActionOnInValid({id}, index, "credit_action")
    // setTrigger(!Trigger)
  }

  useEffect(() =>  {
    // console.log(localStorage.getItem('JWT'))
    // if (props.data.table.filterdByDate) {
    //   if (props.data.table.period) {
    //     props.getDataByDatePeriod({from: props.data.table.period.from, to: props.data.table.period.to, page: props.data.table.currentPage})
    //   } else {
    //     props.getDataByDate({date: props.data.table.day, page: props.data.table.currentPage})
        
    //   }
    // } else {
    //   props.getData({page : props.data.table.currentPage})
    // }
    const interval = setInterval(() => {
      // console.log("interval")
      if (props.data.table.filterdByDate) {
        if (props.data.table.period) {
          props.getDataByDatePeriod({from: props.data.table.period.from, to: props.data.table.period.to, page: props.data.table.currentPage})
        } else {
          props.getDataByDate({date: props.data.table.day, page: props.data.table.currentPage})
          
        }
      } else {
        props.getData({page : props.data.table.currentPage})
      }
    },(900 * 1000));
    return () => clearInterval(interval);
  }, [])


  return (
    <div className={styles.wrapper}>
    <table className={styles.managerKcTable}>
  <thead>
  
    <tr>
      <th>Номер лида</th>
      <th>Дата</th>
      <th>Время</th>
      <th>Имя</th>
      <th>Телефон</th>
      <th>Регион</th>
      <th>Работает?</th>
      <th>Просрочка?</th>
      <th>Халва сайт?</th>
      <th>Халва КЦ</th>
      <th>Альфа КЦ</th>
      <th>Кредит статус</th>
      <th>Кредит действие</th>
    </tr>
  </thead>
  <tbody>
  {props.data.table.data.map((item, index )=>
      <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.date}</td>
      <td>{item.time}</td>
      <td>{item.full_name}</td>
      <td>{item.phone}</td>
      <td>
      <div className={styles.region} onClick={() => {setShowRegion({show: !showRegion.show, id: item.id})}}>
        <div className={styles.currentRegion}  onBlur={() => {setShowRegion({show: false, id: null})}} >
          {item.region}
        </div>
        {showRegion.show && showRegion.id === item.id ? 
        <div className={styles.regionList}>
          {regions.map((item1) => item1 !== item.region ? 
          <div key={item1.value} className={styles.regionList__item} onClick={() => {
            // setData(data.map((buff) => buff.id === item.id ? { ...buff, region: item1 } : buff));
            handleChangeRegion(item.id, item1, index)
            setShowRegion({show: false, id: null})
          }}>
            {item1}
          </div> : null)}
          
        </div> 
        : null}
        </div>
      </td>
      <td  >
        <div className={styles.work}>
        <div className={styles.currentWork}  onBlur={() => {setShowWork({show: false, id: null})}} onClick={() => {setShowWork({show: !showWork.show, id: item.id})}}>
          {item.is_work}
        </div>
        {showWork.show && showWork.id === item.id ? 
        <div className={styles.workList}>
          <div className={styles.workList__item} onClick={() => {
            // setData(data.map((item1) => item1.id === item.id ? { ...item1, is_work: item.is_work === "да" ? "нет" : "да" } : item1));
            handleChangeWork(item.id, item.is_work === "да" ? "нет" : "да", index)
            setShowWork({show: false, id: null})
          }}>
            {item.is_work === "да" ? "нет" : "да"}
          </div>
        </div> 
        : null}
        </div>
        
      </td>
      <td>
      <div className={styles.work}>
        <div className={styles.currentWork}  onBlur={() => {setShowDelay({show: false, id: null})}} onClick={() => {setShowDelay({show: !showDelay.show, id: item.id})}}>
          {item.is_delays}
        </div>
        {showDelay.show && showDelay.id === item.id ? 
        <div className={styles.workList}>
          <div className={styles.workList__item} onClick={() => {
            // setData(data.map((item1) => item1.id === item.id ? { ...item1, is_delays:item.is_delays === "да" ? "нет" : "да" } : item1));
            handleChangeIsDelay(item.id, item.is_delays === "да" ? "нет" : "да", index)
            setShowDelay({show: false, id: null})
          }}>
            {item.is_delays === "да" ? "нет" : "да"}
          </div>
        </div> 
        : null}
        </div>
      </td>
      <td>{item.halva_site}</td>
      <td >
        {item.halva_offer !== "1"  && item.is_work === "да" && item.is_delays === "нет" && item.halva_site !== "Авто" && item.manager !== "Авто" ? <div className={styles.halva__button} onClick={() => {handleChangeHalvaOffer(item.id, 1, index)}}>Отправить халву</div> :  item.is_work !== "да" || item.is_delays !== "нет" || item.halva_site === "Авто" || item.manager === "Авто" ? <div className={styles.halva__no}>Недоступно</div> : <div className={styles.halva__text}>Отправленно</div>}
        </td>
      <td>
        <div className={styles.alphaBank}>
          <div className={styles.alphaYes} onClick={() => {handleChangeAlphaBank(item.id, 1, index)}} style={item.alpha_bank === "1" ? {background: "#39BA42", color: "#ffffff"} : null} disabled={item.alphaBank === "1" ? true : false}>
            Да
          </div>
          <div className={styles.alphaNo} onClick={() => {handleChangeAlphaBank(item.id, 0, index)}} style={item.alpha_bank === "0"? {background: "#EE0064", color: "#ffffff"} : null} disabled={item.alphaBank === "0" ? true : false}>
            Нет
          </div>
        </div>
      </td>
      <td>
        <div className={styles.alphaStatus}>
          <div className={item.credit_action === "3" && (item.credit_status === "Новый" || item.credit_status === null ) ? styles.alphaNew : styles.alphaNew__no} onClick = {item.credit_action !== "0" && item.credit_action !== "1" ? () => {handleChangeCreditStatus(item.id, "Новый", index)} : null}>
            Новый
          </div>
          <div className={item.credit_action === "3" && item.credit_status === "Недозвон 1"  ? styles.alphaSend : styles.alphaSend__no} onClick = {item.credit_action !== "0" && item.credit_action !== "1" ? () => {handleChangeCreditStatus(item.id, "Недозвон 1", index)} : null}>
            Недозвон 1
          </div>
          <div className={item.credit_action === "3" && item.credit_status === "Недозвон 2"  ? styles.alphaGo : styles.alphaGo__no} onClick = {item.credit_action !== "0" && item.credit_action !== "1" ? () => {handleChangeCreditStatus(item.id, "Недозвон 2", index)} : null}>
            Недозвон 2
          </div>
        </div>
      </td>
        <td>
        <div className={styles.alphaAction}>
          <div className={item.credit_action !== "0" && item.credit_action !== "1" && item.manager !== "Авто" ? styles.alphaActionOk : styles.alphaActionOk__no} onClick={item.credit_action !== "0" && item.credit_action !== "1" && item.manager !== "Авто" ? ()=>{handleChangeCreditActionValid(item.id, index)} : null}>
            <div className={styles.alphaOkImg}/>
            Отправить
          </div>
          <div className={item.credit_action !== "1" && item.credit_action !== "0" && item.manager !== "Авто" ? styles.alphaActionNotOk : styles.alphaActionNotOk__no} onClick={item.credit_action !== "0" && item.credit_action !== "1" && item.manager !== "Авто" ? ()=>{handleChangeCreditActionInValid(item.id, index)} : null}>
            <div className={styles.alphaNotOkImg}/>
            Невалид
          </div>
        </div>
      </td>
    </tr> 
    )}
  </tbody>
</table>
</div>
  );
}

function mapStateToProps(state) {
  return {
    data: state,
  };
}

export default connect(mapStateToProps, actions)(ManagerKC);

