import styles from "./login.module.css";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";


// import { connect } from "react-redux";

function Login(props) {

  const [isLoading, setLoading] = useState(false)

  const history = useHistory();

  const validSchema = Yup.object().shape({
    email: Yup.string()
    .required()
    .matches(/[a-zA-Z0-9]+$/),
    password: Yup.string()
      .required()
      .min(8)
      .max(32)
      .matches(/[a-zA-Z0-9]+$/),
  });


  async function submit(values) {
    setLoading(!isLoading)
    const res = await props.signIn(values);
    // console.log(res)
    if (res.status){
      history.push('/')
      setLoading(!isLoading)
    } else  {
      console.log("err")
      setLoading(!isLoading)

    }
    setLoading(!isLoading)

    
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.label}>Авторизация в CRM</div>
        <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validSchema}
            onSubmit={async (values) => {
              await submit(values);
            }}
          >
            {({ values, errors, touched, setFieldError }) => (
              <Form className={styles.loginForm}>
                <div className={styles.content}>
                  <div className={`${styles.field__login} ${styles.row}`}>
                    <Field
                      className={touched.email && errors.email
                          ? styles.inputError
                          : styles.input
                      }
                      type="string"
                      name="email"
                      placeholder="Ваш логин"
                    ></Field>
                  </div>
                  <div className={`${styles.field__password} ${styles.row}`}>
                    <Field
                      className={
                        touched.password && errors.password
                          ? styles.inputError
                          : styles.input
                      }
                      type="password"
                      name="password"
                      placeholder="Ваш пароль"
                    ></Field>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button
                    className={styles.login__button}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? 'Loading...' : 'Войти'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className={styles.bottom__text}>
            <span>Забыли пароль / логин?</span> свяжитесь со своим руководителем для восстановления
          </div>

      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuth: state,
    errorMessage: state.auth.errorMessage,
  };
}

export default connect(mapStateToProps, actions)(Login);
