import styles from "./mainpage.module.css";
import React, { useState, useEffect } from "react";
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import DatePicker from 'react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import { connect } from "react-redux";
import * as actions from "../../actions";
import Header from "../Header/Header";
import AdminTable from "../Tables/AdminTable";
import ManagerKC from "../Tables/ManagerKC";
import ManagerAlpha from "../Tables/ManagerAlpha";


function Mainpage(props) {

  const buff = new Date(Date.now())

  const [ShowSearch, setShowSearch] = useState(false)
  const [ShowSearchInput, setShowSearchInput] = useState(false)
  const [columnList, setColumnList] = useState([{name: "Время", value: "Time"},{name: "Имя", value: "Name"},{name: "Дата", value: "Date"},{name: "Телефон", value: "Phone"}])
  const [searchTerm, setSearchTerm] = useState("")
  const [searchTarget, setSearchTarget] = useState("")
  const [searchValue, setSearchValue] = useState("")

  const [tableView, setTableView] = useState(0)

  const [ShowDateFilter, setShowDateFilter] = useState(true)
  const [selectedDayStart, setSelectedDayStart] = useState({
    year: buff.getFullYear(),
    month: buff.getMonth()+1,
    day: buff.getDate(),
  })
  const [selectedDayEnd, setSelectedDayEnd] = useState({
    year: buff.getFullYear(),
    month: buff.getMonth()+1,
    day: buff.getDate(),
  })
  const [isToday, setIsToday] = useState(false)
  const [isTomorrow, setIsTomorrow] = useState(false)
  const [isMonth, setIsMonth] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  const [trigger, setTrigger] = useState(false)

  const [showPages, setShowPages] = useState(false)

  const [preload, setPreload] = useState(false)


const results = !searchTerm || searchTarget
? columnList
: columnList.filter(column =>
    column.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
  );

  const handleSearchTargetValue = (name) => {
    const even = (element) => element.name === name;
    if (results.some(even)) {
      setSearchTarget(results.find(even))
    } else {
      setSearchTarget("")
    }
  }

  const startDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Начало"
      value={selectedDayStart ? `${selectedDayStart.day}.${selectedDayStart.month}.${selectedDayStart.year}` : ''}
      style={{
        width: '100%',
        maxWidth: '131px',
        height: '36px',
        textAlign: 'left',
        padding: '6px 12px 6px 16px',
        fontSize: '12px',
        fontFamily: 'R-Medium',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        color: '#6200EE',
        outline: 'none',
      }}
      className={styles.inputCalendarStart} 
    />
  )

  const endDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Начало"
      value={selectedDayEnd ? `${selectedDayEnd.day}.${selectedDayEnd.month}.${selectedDayEnd.year}` : ''}
      style={{
        width: '100%',
        maxWidth: '131px',
        height: '36px',
        textAlign: 'left',
        padding: '6px 12px 6px 16px',
        fontSize: '12px',
        fontFamily: 'R-Medium',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        color: '#6200EE',
        outline: 'none',
      }}
      className={styles.inputCalendarStart} 
    />
  )

  const selectDateButton = async (buttonIndex) => {
    setPreload(true)
    if ((buttonIndex === 1 && isToday) || (buttonIndex === 2 && isTomorrow) || (buttonIndex === 3 && isMonth)) {
      return null
    } else {
      if (buttonIndex === 1) {
        setIsToday(true)
        setIsTomorrow(false)
        setIsMonth(false)
        const d = new Date(Date.now())
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        await props.getDataByDate({date: [year, month, day].join('-'), page: 1})
        setPreload(false)
      } else {
        if (buttonIndex === 2) {
          setIsToday(false)
          setIsTomorrow(true)
          setIsMonth(false)
          let d = new Date(Date.now()+1)
          d.setDate(d.getDate()-1)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        await props.getDataByDate({date: [year, month, day].join('-'), page: 1})
        setPreload(false)
        } else {
          setIsToday(false)
          setIsTomorrow(false)
          setIsMonth(true)
          const d = new Date(Date.now())
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()


    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

        await props.getDataByDatePeriod({from:[year, month, "01"].join('-'),to:[year, month, day].join('-'), page: 1})
        setPreload(false)
        }
      }
    }
  }

  const selectedPeriod = async (value) => {
    setPreload(true)
    setIsToday(false)
    setIsTomorrow(false)
    setIsMonth(false)
    // console.log(value)
    setSelectedDayEnd(value)
    await props.getDataByDatePeriod({from: toDate(selectedDayStart),to: toDate(value), page: 1})
    setPreload(false)
  }
  
  const toDate = (dateObj) => {
    let date = dateObj.day.toString()+'.'+dateObj.month.toString()+'.'+dateObj.year.toString()
    let parts = date.split(".")
    if (parts[0].length < 2) 
        parts[0] = '0' + parts[0];
    if (parts[1].length < 2) 
        parts[1] = '0' + parts[1];

  return [parts[2], parts[1], parts[0]].join('-')
  }

  const changeIsWorking = async (id, value) => {
    await props.changeIsWorking({id, name_field: "is_working", value})
    setTrigger(!trigger)
  }

  const role = localStorage.getItem('ROLE')

  const changePage = async (page) => {
    setPreload(true)
    // console.log(preload)
    if (props.mainPageState.table.filterdByDate) {
      if (props.mainPageState.table.period) {
        await props.getDataByDatePeriod({from: props.mainPageState.table.period.from, to: props.mainPageState.table.period.to, page})
      setPreload(false)

      } else {
        await props.getDataByDate({date: props.mainPageState.table.day, page})
        setPreload(false)
        
      }
    } else {
      await props.getData({page})
      setPreload(false)

    }
  }



  useEffect(() =>  {
    props.getCurrentUser()
    changePage(1)
  }, [trigger])
  


  return (
    <div className={styles.container}>
      <Header/>
      <div className={styles.searchSection} style={ShowSearch ? {marginBottom: "20px"}: null}>
        <div className={styles.searchSection__top}>
        {role === "manager-kc" ? 
          <div className={styles.top__action}>Действия:</div>
        : null}
        {role === "admin" ? 
          <div className={styles.top__view}>Выбранный вид отображения лидов:</div>
        : null}
          
          
          <div className={styles.top__search}>Поиск
          <div className={styles.top__flag} onClick={()=>{setShowSearch(!ShowSearch)}}>
            <div className={styles.flag__text}>
              {ShowSearch ? "Свернуть" : "Развернуть"}
            </div>
            {
              ShowSearch ?
              <div className={styles.arrowUp}/> :
              <div className={styles.arrowDown}/>
            }
          </div></div>
          

        </div>

        {
              ShowSearch ?
              <div className={styles.searchSection__bottom}>
          
            {role === "manager-kc" ? 
            <div className={styles.bottom__action}>
            <div className={styles.action__buttonOn} onClick={() => {changeIsWorking(props.mainPageState.auth.id, 1)}} style={props.mainPageState.auth.isWorking === "1" ? {color: "#ffffff", background: "#39BA42"} : null }>
              <div className={styles.buttonOn__img}/>
              <div className={styles.buttonOn__text}>
                Включить КЦ
              </div>                
            </div>
            <div className={styles.action__buttonOff} onClick={() => {changeIsWorking(props.mainPageState.auth.id, 0)}} style={props.mainPageState.auth.isWorking === "0" ? {color: "#ffffff", background: "#EE0064"} : null }>
              <div className={styles.buttonOff__img}/>
              <div className={styles.buttonOff__text}>
                Выключить КЦ
              </div>  
            </div>
        </div>
            : null}

        {role === "admin" ? 
          <div className={styles.bottom__view}>
            <div className={styles.view__full} onClick={()=>setTableView(0)} style={tableView === 0 ? {color: "#ffffff", background: "#6200EE"} : null}>Полный</div>
            <div className={styles.view__credits} onClick={()=>setTableView(2)} style={tableView === 2 ? {color: "#ffffff", background: "#6200EE"} : null}>КЦ кредиты</div>
            <div className={styles.view__alfa} onClick={()=>setTableView(1)} style={tableView === 1 ? {color: "#ffffff", background: "#6200EE"} : null}>Менеджер Альфа</div>
          </div>
        : null}
            
          
          <div className={styles.bottom__search}>
            <div className={styles.search__target}>
              <div className={styles.target__text}>
                СТОЛБЕЦ
              </div>
              <div className={styles.searchSelect}>
                <div className={styles.target__input} onChange={e => {setSearchTerm(e.target.value)
                                                                handleSearchTargetValue(e.target.value)}} 
                                                      onFocus={()=>{setShowSearchInput(true)}} 
                                                      onBlur={()=>{setShowSearchInput(false)}}>
                  <input type="text" placeholder="Введите или Выберите" className={styles.input}></input>
                  <div className={styles.input__arrow} onClick={()=>{setShowSearchInput(!ShowSearchInput)}}/>
                </div>
                {ShowSearchInput ? <div className={styles.target__list}>
                  {results.map(item => (
                    <div className={styles.selectList__item} onClick={() => setSearchTarget(item)}>
                      {item.name}
                    </div> 
                  ))}
                </div> : null}
              </div>
            </div>

            <div className={styles.search__value}>
              <div className={styles.value__text}>
                ЗНАЧЕНИЕ
              </div>
                  <input type="text" placeholder="Введите значение" className={styles.value__input} value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
            </div>
            <div className={styles.search__button}>
              <div className={styles.search__plus}/>
              <div className={styles.buttonSearch}>
                <div className={styles.buttonSearch__icon}/>
                <div className={styles.buttonSearch__Text}>
                  ПОИСК
                </div>
              </div>
            </div>
          </div>
        </div> :
              null
            }
        


      </div>
      <div className={styles.dateSection}>
        <div className={styles.dateSection__top}>
          <div className={styles.dateSection__text}>Выберите дату поступления лидов:</div>

          <div className={styles.top__flag} onClick={()=>{setShowDateFilter(!ShowDateFilter)}}>
            <div className={styles.flag__text}>
            {ShowDateFilter ? "Свернуть" : "Развернуть"}
            </div>
            {
              ShowDateFilter ?
              <div className={styles.arrowUp}/> :
              <div className={styles.arrowDown}/>
            }
          </div>

        </div>
        {
              ShowDateFilter ?
              <div className={styles.searchSection__bottom}>
          <div className={styles.bottom__dateFilter}>
            <div className={styles.startText}>С</div>
            <DatePicker
                  value={selectedDayStart}
                  onChange={setSelectedDayStart}
                  colorPrimary="#6200EE"
                  renderInput={startDateInput} 
            />
            <div className={styles.endText}>по</div>
            <DatePicker
                  value={selectedDayEnd}
                  // onChange={setSelectedDayEnd}
                  onChange={selectedPeriod}
                  colorPrimary="#6200EE"
                  renderInput={endDateInput} 
            />
            <div className={isToday ? styles.buttonActivePurple : styles.buttonDate} onClick={() => selectDateButton(1)}>Сегодня</div>
            <div className={isTomorrow ? styles.buttonActivePurple : styles.buttonDate} onClick={() => selectDateButton(2)}>Вчера</div>
            <div className={isMonth ? styles.buttonActivePurple : styles.buttonDate} onClick={() => selectDateButton(3)}>С начала месяца</div>

          </div>
          <div className={styles.paginationSection}>
            <div className={styles.pagination__text}>
              {props.mainPageState.table.from}-{props.mainPageState.table.to} ЗАПИСЬ ИЗ {props.mainPageState.table.total}
            </div>
            {props.mainPageState.table.currentPage !== 1 ? <div className={styles.pagination__startActive} onClick={() => changePage(1)}/> : <div className={styles.pagination__start}/>}

            {props.mainPageState.table.currentPage !== 1 ? <div className={styles.pagination__prevActive} onClick={() => changePage(props.mainPageState.table.currentPage - 1)}/> : <div className={styles.pagination__prev}/>}            

            <div className={styles.pagination__current} onClick = {() => setShowPages(!showPages)}>
              <div className={styles.currentPage__text}>
                {props.mainPageState.table.currentPage}
              </div>
              <div className={styles.arrowDown}/>
              {showPages ? <div className={styles.pageList}>
              {(props.mainPageState.table.lastPage - props.mainPageState.table.currentPage) > 2 ? 
                <div className={styles.pageList__item} onClick={() => changePage(props.mainPageState.table.currentPage + 2)}>
                  {props.mainPageState.table.currentPage + 2}
                </div> : null}
                {(props.mainPageState.table.lastPage - props.mainPageState.table.currentPage) > 1 ? 
                <div className={styles.pageList__item} onClick={() => changePage(props.mainPageState.table.currentPage + 1)}>
                  {props.mainPageState.table.currentPage + 1}
                </div>
                  : null}
                <div className={styles.pageList__itemCurrent}>
                  {props.mainPageState.table.currentPage}
                </div>
                {props.mainPageState.table.currentPage > 1 ? 
                <div className={styles.pageList__item} onClick={() => changePage(props.mainPageState.table.currentPage - 1)}>
                  {props.mainPageState.table.currentPage-1}
                </div> : null}
                {props.mainPageState.table.currentPage > 2 ? 
                <div className={styles.pageList__item} onClick={() => changePage(props.mainPageState.table.currentPage - 2)}>
                  {props.mainPageState.table.currentPage-2}
                </div>
                  : null}
                
              </div> : null}
              

            </div>

            
              {props.mainPageState.table.lastPage !== props.mainPageState.table.currentPage ? <div className={styles.pagination__nextActive} onClick={() => changePage(props.mainPageState.table.currentPage+1)}/> : <div className={styles.pagination__next}/>}

              {props.mainPageState.table.lastPage !== props.mainPageState.table.currentPage ? <div className={styles.pagination__lastActive} onClick={() => changePage(props.mainPageState.table.lastPage)}/> : <div className={styles.pagination__last}/>}
          </div>
        </div> :
              null
            }
      </div>
      <div className ={styles.statSection}>
        <div className={styles.stat}>
          <div className={styles.stat__label}>
            Статистика по КЦ
          </div>
          <div className={styles.stat__text}>
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Отправлено авто: <span> {props.mainPageState.table.statistic.number_leads_sent_auto}</span></div>        
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Валид: <span> {props.mainPageState.table.statistic.number_valid_leads}</span></div>        
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Невалид: <span> {props.mainPageState.table.statistic.number_invalid_leads}</span></div>
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Недозвонов: <span> {props.mainPageState.table.statistic.number_missed_leads }</span></div>
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Допродаж МТБ с сайта: <span> {props.mainPageState.table.statistic.resale_from_site}</span></div>
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Допродаж МТБ с КЦ: <span> {props.mainPageState.table.statistic.resale_from_offer}</span></div>
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Допродаж МТБ Авто: <span> {props.mainPageState.table.statistic.number_resale_from_offer_auto}</span></div>
            <div className={styles.stat__item} style={role === "manager-alpha" ? {display: "none"} : null}>Согласий Альфа с КЦ: <span> {props.mainPageState.table.statistic.number_agreed_alpha_kc}</span></div>
            <div className={styles.stat__item}>Альфа успешно: <span> {props.mainPageState.table.statistic.number_alpha_success}</span></div>
            <div className={styles.stat__item}>Альфа в работе: <span> {props.mainPageState.table.statistic.number_alpha_in_process}</span></div>
            <div className={styles.stat__item}>Альфа проигрыш: <span> {props.mainPageState.table.statistic.number_alpha_losing}</span></div>
          </div>
        </div>
      </div>
      {preload || (props.mainPageState.table.lastPage === null) ? <div className={styles.preload}></div> :
      
        (role === "admin" && tableView === 0 ? <AdminTable/> 
        : role === "manager-alpha" || (role === "admin" && tableView === 1) ? <ManagerAlpha/> 
        : role === "manager-kc" || (role === "admin" && tableView === 2) ? <ManagerKC/> : null)
       
    }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    mainPageState: state,
  };
}

export default connect(mapStateToProps, actions)(Mainpage);

