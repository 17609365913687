import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import axios from "axios";

import authGuard from "./HOCs/authGuard";


import Reducers from "./reducers";
import App from './components/App';
import Mainpage from './components/Mainpage/Mainpage';
import Login from './components/Login/Login';

let history = createBrowserHistory();

const jwt = localStorage.getItem("JWT");
const user = localStorage.getItem('USER');
const userId = localStorage.getItem('USER_ID');

axios.defaults.headers.common["Authorization"] = jwt;


ReactDOM.render(
  <Provider
  store={createStore(
    Reducers,
    {
      auth: {
        token: jwt,
        isAuthenticated: jwt ? true : false,
        username: user,
        userId
      },
    },
    applyMiddleware(reduxThunk)
  )}>
    <BrowserRouter history={history}>
      <App>
        <Route exact path="/signIn" component={Login} />
        <Route exact path="/" component={authGuard(Mainpage)} />
        {/* <Route exact path="/" component={Mainpage} /> */}

      </App>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)