export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const CHANGE_IS_WORKING = 'CHANGE_IS_WORKING';
export const TABLE_GET_DATA = 'TABLE_GET_DATA';
export const TABLE_GET_DATA_BY_DATE = 'TABLE_GET_DATA_BY_DATE';
export const TABLE_CHANGE_FIELD ='TABLE_CHANGE_FIELD';
// export const TABLE_CHANGE_WORK ='TABLE_CHANGE_WORK';
// export const TABLE_CHANGE_REGION ='TABLE_CHANGE_REGION';
// export const TABLE_CHANGE_ALPHA_WHERE = 'TABLE_CHANGE_ALPHA_WHERE';
// export const TABLE_CHANGE_IS_DELAY = 'TABLE_CHANGE_IS_DELAY';
export const TABLE_GET_DATA_BY_DATE_PERIOD = 'TABLE_GET_DATA_BY_DATE_PERIOD';
