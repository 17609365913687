import axios from "axios";
import {
  AUTH_ERROR,
  AUTH_SIGN_IN,
  AUTH_SIGN_OUT,
  TABLE_GET_DATA,
  TABLE_GET_DATA_BY_DATE,
  TABLE_GET_DATA_BY_DATE_PERIOD,
  TABLE_CHANGE_FIELD,
  GET_CURRENT_USER,
} from "./types";

export const signIn = (data) => {
  return async (dispatch) => {
      try {
        // console.log(data)
          const res = await axios.post('https://crm.1bank.by/api/login',  data);
        // console.log([res])

          dispatch({
              type: AUTH_SIGN_IN,
              payload: res.data
          });

          localStorage.setItem('NAME', res.data.name);
          localStorage.setItem('ROLE', res.data.role);
          localStorage.setItem('JWT', res.data.access_token);

          return {
              status: true
          };
      } catch (err) {
          dispatch({
              type: AUTH_ERROR,
              payload: err.response.data.error
          });

          return {
              status: false,
              errorMessage: err.response.data.error,
          };
      }
  }
};

export const signOut = () => {
  return (dispatch) => {
    localStorage.removeItem("JWT");
    localStorage.removeItem("NAME");
    localStorage.removeItem('ROLE');

    dispatch({
      type: AUTH_SIGN_OUT,
      payload: "",
    });
  };
};

export const authCheck = () => {
  return async () => {
    try {
      const res = await axios.get('https://crm.1bank.by/api/checkAuth', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('JWT')}`
        }
      });
      // console.log("here")
                // console.log(res.data.status_auth)
      return (res.data.status_auth)
    } catch (err) {
      // console.log(err)
      return false;
    }
  };
};

export const getData = (data) => {
  // console.log(data)
  return async (dispatch) => {
      try {
        // console.log("getData")
        // console.log(localStorage.getItem('JWT'))
        const res = await axios.get(`https://crm.1bank.by/api/leads?page=${data.page}`
        // const res = await axios.get('http://localhost/api/leads'    
        ,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        }
        );
        // console.log("work")

          dispatch({
              type: TABLE_GET_DATA,
              payload: res.data
          });

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const getDataByDate = (data) => {
  // console.time('DataByDate')
  return async (dispatch) => {
      try {
        const res = await axios.get(`https://crm.1bank.by/api/filterDateByButtons?date=${data.date}&page=${data.page}`, {
        // const res = await axios.get(`http://localhost/api/filterDateByButtons?date=${data}`, {
        // const res = await axios.get(`https://crm.1bank.by/api/leads?page=${data.page}`
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });
        // await console.log(res)
        // console.log({data: res.data, day: data.date})

          dispatch({
              type: TABLE_GET_DATA_BY_DATE,
              payload: {data: res.data, day: data.date}
          });
        // console.timeEnd('DataByDate')


          return {
            
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const getDataByDatePeriod = (data) => {
  return async (dispatch) => {
      try {
        const res = await axios.get(`https://crm.1bank.by/api/filterByDate?from=${data.from}&to=${data.to}&page=${data.page}`, {
        // const res = await axios.get(`http://localhost/api/filterByDate?from=${data.from}&to=${data.to}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        // console.log({data: res.data, period: data})

          dispatch({
              type: TABLE_GET_DATA_BY_DATE_PERIOD,
              payload: {data: res.data, period: {from: data.from, to: data.to}}
          });

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeWork = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateWork', data,{
        // await axios.patch('http://localhost/api/leads/updateWork', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if (res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.is_work, index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeRegion = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateRegion', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if (res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.region, index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};



export const changeIsDelay = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateDelays', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if (res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.is_delays, index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeAlphaStatus = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateAlphaStatus', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if (res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.alpha_status, index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeCreditStatus = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateCreditStatus', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if (res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.credit_status, index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeAlphaWhere = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateAlphaWhere', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if ( res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.alpha_where, index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeHalvaOffer = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateHalvaOffer', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if ( res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.halva_offer.toString(), index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeAlphaBank = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateAlphaBank', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if ( res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.alpha_bank.toString(), index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};



export const changeAlphaAction = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateAlphaAction', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if ( res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: data.alpha_action.toString(), index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeCreditActionOnValid = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        // http://localhost/api/leads
        const res = await axios.post('https://crm.1bank.by/api/leads/updateCreditActionAndSendLead', data,{
        // await axios.post('http://localhost/api/leads/updateCreditActionAndSendLead', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if ( res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: "1", index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeCreditActionOnInValid = (data, index, fieldName) => {
  return async (dispatch) => {
      try {
        const res = await axios.patch('https://crm.1bank.by/api/leads/updateCreditActionOnInvalid', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

        if ( res.status === 200 ) {
          // console.log("dispatch")
          dispatch({
            type: TABLE_CHANGE_FIELD,
            payload: {value: "0", index, fieldName}
        });
        }

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const changeIsWorking = (data) => {
  // console.log(data)
  return async () => {
      try {
        await axios.patch('https://crm.1bank.by/api/users/update', data,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        });

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};

export const getCurrentUser = () => {
  return async (dispatch) => {
      try {
        // console.log("getData")
        // console.log(localStorage.getItem('JWT'))
        const res = await axios.get('https://crm.1bank.by/api/currentUser',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('JWT')}`
          }
        }
        );

          dispatch({
              type: GET_CURRENT_USER,
              payload: res.data
          });

          return {
              status: true
          };
      } catch (err) {
          console.log(err)
      }
  }
};